<template>
  <v-dialog
    content-class="not-printable"
    v-model="open"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3 color3Text--text"
        v-on="on"
        :loading="loading"
        :disabled="loading || !data"
        text x-small
      >
        <v-icon class="mr-3" small>fas fa-clipboard-list</v-icon>
      View Event Summary</v-btn>
    </template>
    <registration-summary
      :tournament="tournament"
      :registrationData="data"
    ></registration-summary>
  </v-dialog>
</template>

<script>
import RegistrationSummary from './RegistrationSummary.vue'
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['data', 'loading'],
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters(['tournament'])
  },
  watch: {
    open: function (val) {
      this.$store.commit(mutations.SET_TOURNAMENT_REGDATA, val ? this.data : null)
    }
  },
  components: {
    RegistrationSummary
  }
}
</script>

<style>

</style>
