<template>
  <div style="display: contents">
    <v-btn
      color="color3"
      text
      :small="!xs"
      :x-small="xs"
      @click.stop="onEmailClick"
    >
      Message these teams ({{teams.length}})
    </v-btn>
    <v-navigation-drawer
      right
      v-model="drawer"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
        <v-toolbar color="color2 color2Text--text" tile dense class="top">
          <v-toolbar-title>{{window === 0 ? 'Email' : 'SMS'}} Compose</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            fab
            small
            @click.stop="cancel(true)"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col cols="6">
              <v-btn
                color="color3 color3Text--text"
                @click.stop="send"
                :disabled="!valid"
                :loading="loading"
                block
                class="py-9"
              >
                Send
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error white--text"
                @click.stop="cancel"
                :disabled="loading"
                block
                class="py-9"
              >
              Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-container>
        <v-row dense>
            <!-- Recipients -->
            <v-col cols="12">
              <v-card color="grey lighten-3">
                <v-card-title class="subheading" style="min-height: 68px">
                  <b>Recipients</b>
                  <v-chip color="color3Text color3--text" class="ml-2">
                    <v-avatar
                      color="color3"
                      class="color3Text--text mr-2"
                      size="36"
                      v-text="selectedPlayers.length "
                    ></v-avatar>
                    {{selectedPlayers.length}} Players On {{ selectedTeams.length }} Teams
                  </v-chip>
                </v-card-title>
              </v-card>
            </v-col>
            <!-- Message -->
          <v-col cols="12">
            <v-card color="grey lighten-3">
              <v-overlay absolute v-if="selectedPlayers.length === 0"></v-overlay>
              <v-card-title class="subheading">
                <b>Message</b>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="allowSms"
                  color="color3"
                  x-small text
                  @click.stop="toggleSms"
                >
                  Use {{window === 1 ? 'email' : 'sms'}}
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <!-- Message -->
              <v-window v-model="window" touchless>
                <v-window-item key="email">
                  <v-card-text>
                    <!-- Subject/Body -->
                    <v-row dense>
                      <v-col cols="12" class="pa-0">
                        <div class="body-2">Subject:</div>
                        <v-text-field
                          label="Subject*"
                          v-model="sparkpost.subject"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pa-0">
                        <div class="body-2">Body:</div>
                        <html-editor v-model="sparkpost.paragraph[0].text" :no-header="true" :no-card="true"></html-editor>
                      </v-col>
                    </v-row>
                    <!-- Closing -->
                    <v-row dense class="pt-4">
                      <v-col cols="12">
                        <div class="body-2">Closing:</div>
                        <v-text-field
                          label="Closing*"
                          v-model="sparkpost.closing"
                          solo
                          persistent-hint
                          hint="i.e. Thank you, See you at the beach, Sincerely"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- Signature -->
                    <v-row dense>
                      <v-col cols="12">
                        <div class="body-2">Signature:</div>
                        <v-text-field
                          label="Signature*"
                          v-model="sparkpost.signature"
                          solo
                          persistent-hint
                          hint="Your name or you organizations name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>
                <!-- SMS -->
                <v-window-item key="sms">
                  <v-card-text>
                    <!-- Body -->
                    <v-row dense>
                      <v-col cols="12" class="pa-0">
                        <v-textarea
                          label="Body"
                          counter="160"
                          rows="3"
                          background-color="white"
                          color="black"
                          v-model="smsBody"
                        ></v-textarea>
                        <div></div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import * as mutations from '@/store/MutationTypes'
import Sparkpost from '@/classes/Sparkpost'
import HtmlEditor from '@/components/Utils/HtmlEditor.vue'
import flatten from '@/helpers/ArrayFlatten'
import { mapGetters } from 'vuex'

export default {
  props: ['teams', 'tournament', 'xs'],
  data () {
    return {
      drawer: false,
      window: 0,
      sparkpost: new Sparkpost(),
      smsBody: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    allowSms () {
      return this.tournament.smsEnabled
    },
    sparkpostValid () {
      return this.window === 0 && this.sparkpost.valid
    },
    smsValid () {
      return this.window === 1 && this.smsBody && this.smsBody.length > 3
    },
    valid () {
      return (this.sparkpostValid || this.smsValid) && this.teams.length > 0
    },
    teamIds () {
      return this.teams.map(m => m.teamId || m.team.id)
    },
    selectedTeams () {
      return this.tournament.teams.filter(f => this.teamIds.includes(f.id))
    },
    selectedPlayers () {
      return flatten(this.selectedTeams.map(team => {
        return team.players
      }))
    },
    dto () {
      return this.window === 1 ? {
        tournamentId: this.tournament.id,
        includeWithdrawn: false,
        includeWaitlist: false,
        onlyWithdrawn: false,
        onlyWaitlist: false,
        divisionIds: [],
        smsBody: this.smsBody,
        when: null,
        recipients: [],
        teamIds: this.teamIds
      } : {
        tournamentId: this.tournament.id,
        includeWithdrawn: false,
        includeWaitlist: false,
        onlyWithdrawn: false,
        onlyWaitlist: false,
        divisionIds: [],
        sparkpost: this.sparkpost,
        when: null,
        recipients: [],
        teamIds: this.teamIds
      }
    }
  },
  methods: {
    onEmailClick () {
      this.drawer = true
      // const teamIds = this.teams.map(m => m.teamId || m.team.id)
      // this.$store.commit(mutations.SET_TEAM_TO_MSG, teamIds)
      // this.$router.push({ name: 'tournament-admin', parameters: { tournamentId: this.tournamentId }, query: { tab: 'emailer' } })
    },
    init () {
      this.sparkpost = new Sparkpost()
      this.sent = false
      this.advanced = false
      this.sparkpost.subject = `UPDATE: ${this.tournament.name}`
      this.sparkpost.closing = 'See ya at the beach,'
      this.sparkpost.signature = this.user.fullname
      this.sparkpost.preheader = `Tournament Update from ${this.tournament.organization.name}`
      this.window = 0
      this.smsBody = null
    },
    send () {
      this.loading = true
      this.$VBL.post.notification(this.dto)
        .then(r => {
          this.sent = true
          this.$emit('sent')
          this.drawer = false
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    cancel () {
      this.drawer = false
    },
    toggleSms () {
      if (this.window === 0) {
        this.window = 1
      } else {
        this.window = 0
      }
    }
  },
  watch: {
    drawer: function (val) {
      if (val) {
        this.init()
      }
    }
  },
  components: {
    HtmlEditor
  }
}
</script>
