<template>
  <v-btn
    color="color3 color3Text--text"
    :loading="loading"
    :disabled="loading"
    @click.stop="download"
    x-small text
  >
    <v-icon small class="mr-3">fas fa-money-check-alt</v-icon>
    Download Financial Report
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
import Papa from 'papaparse'
import { firstBy } from 'thenby'
import { formatArray } from '@/Filters'

export default {
  data () {
    return {
      loading: false,
      registrations: []
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    reportData () {
      return this.registrations.map(r => {
        return {
          Date: r.date,
          Event: r.event,
          Division: r.division,
          Team: r.team,
          Status: r.status,
          'Registered On': r.dtRegistered,
          Amount: r.amount,
          'Cart Fees': r.cartFees,
          'Amount Paid': r.amountPaid,
          Refunded: r.refunded,
          'VBL Fee': r.vblFee,
          'Affiliate Fee': r.affiliateFee,
          'Stripe Fee': r.stripeFee,
          'Promo Amount': r.promoAmount,
          Promo: r.promo,
          'Order Id': r.orderId,
          'Other Items in bag': r.bagItems,
          'Cart Fees Included': formatArray(r.cartFeeDescriptions)
        }
      })
    }
  },
  methods: {
    download () {
      this.loading = true
      this.$VBL.tournament.getFinancialData(this.tournament.id)
        .then(response => {
          this.registrations = response.data.sort(firstBy('DtRegistered'))
          this.postDownload()
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.loading = false })
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    postDownload (coaches) {
      var fileName = `${this.tournament.name.replace(/ /g, '_')}_Financial_Report.csv`
      var data = this.reportData
      this.createFileForDownload(data, fileName)
    }
  }
}
</script>
